<template>
  <div>
    <el-row type="flex" justify="end">
      <el-button @click="$router.go(-1)">返回</el-button>
    </el-row>
    <div class="bgFFF">
      <el-form label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="任务ID:">{{pushId}}</el-form-item>
            <el-form-item label="推送状态:"><span class="colorC">{{pushStatus | filterStatus}}</span></el-form-item>
            <el-form-item v-if="imgUrl">
                <div class="right-con" :style="{backgroundImage: 'url(' + imgUrl + ')'}">
                  </div>
            </el-form-item>
            <el-form-item label="标题:">{{title}}</el-form-item>
            <!-- <el-form-item label="推送分类:">{{category | categoryFilter}}</el-form-item> -->
            <el-form-item
              label="内容:"
            >{{content}}</el-form-item>
            <el-form-item label="目标应用:">{{targetTerminal | targetTerminalFilter}}</el-form-item>
            <el-form-item
              label="跳转类型:"
            >{{jumpUrl}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="推送时间:">{{pushTime}}</el-form-item>
            <el-form-item label="推送用户标签:">
              <el-tag style="margin-right: 10px;background-color:rgba(153, 0, 153, 1);color:#fff" v-for="item in labelNameList" :key="item">{{item}}</el-tag>
              <!-- <el-tag style="margin-right: 10px">标签一</el-tag>
              <el-tag style="margin-right: 10px">标签一</el-tag>
              <el-tag style="margin-right: 10px">标签一</el-tag> -->
            </el-form-item>
            <el-form-item label="任务描述:">{{remarks}}</el-form-item>
            <el-form-item label="创建时间:">{{createTime}}</el-form-item>
            <el-form-item label="最后操作人:">{{updateUserName}}</el-form-item>
            <el-form-item label="最后修改时间:">{{updateTime}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  filters: {
    filterStatus(val) {
      const arr = ["已保存", "待推送", "已推送"];
      return arr[val - 1];
    },
    categoryFilter(val) {
      const arr = ["系统通知", "活动通知", "资讯热点", "商品推介"];
      return arr[val - 1];
    },
    targetTerminalFilter(val) {
      const arr = ["全国app", "石家庄app"];
      return arr[val - 1];
    }
  },
  data() {
    return {
      pushId: this.$route.query.pushId,
      pushStatus: "",
      title: "",
      imgUrl: "",
      category: "",
      content: "",
      targetTerminal: "",
      jumpUrl: "",
      pushTime: "",
      labelNameList: [],
      remarks: "",
      createTime: "",
      updateUserName: "",
      updateTime: "",
      jumpUrlName: ""
    };
  },
  mounted() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      const opt = {
        url: "/acb/2.0/pushManage/detail/" + this.$route.query.pushId,
        method: "get",
        data: {},
        success: res => {
          let data = res.value;
          this.pushId = data.pushId;
          this.pushStatus = data.pushStatus;
          this.title = data.title;
          this.category = data.category;
          this.content = data.content;
          this.targetTerminal = data.targetTerminal;
          this.jumpUrl = data.jumpUrl;
          this.pushTime = data.pushTime;
          this.labelNameList = data.labelNameList;         
          this.remarks = data.remarks;
          this.updateUserName = data.updateUserName;
          this.createTime = data.createTime;
          this.updateTime = data.updateTime;
          this.imgUrl = data.imgUrl;
          if (data.jumpType == "1") {
            this.jumpUrl = "启动app";
          } else if (data.jumpType == "2") {
            this.jumpUrl = data.jumpUrl;
          } else if (data.jumpType == "3") {
             this.jumpUrl = data.pageName;
          }
        }
      };
      this.$request(opt);
    },
  }
};
</script>
<style lang="stylus" scoped>
  .colorC
    color #1C6FFF
  .right-con
    width 130px
    height 130px
    background-size 100% 100%
    background-size cover
    
</style>
